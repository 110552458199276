<template>
  <div class="grid">
    <div class="col-12">
      <div class="mb-2">
      <router-link
          :to="{name: 'spectacleCrud'}"
      ><i class="pi pi-arrow-left"/> Retour
      </router-link>
        </div>
      <div class="card">
        <Toast/>
        <h5>Spectacle - {{this.spectacle.name}}</h5>

        <DataTable :value="this.reservations" :paginator="true" :rows="10" :loading="this.loadingReservations"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                   currentPageReportTemplate="{first} à {last} des {totalRecords} spectacles" responsiveLayout="scroll">
          <Column field="name" header="École" :sortable="true" headerStyle="width:20%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title"></span>
              {{slotProps.data.name}}
            </template>
          </Column>

          <Column field="companions" header="Nombre d'accompagnateurs" :sortable="true" headerStyle="width:25%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title"></span>
              {{slotProps.data.companions}}
            </template>
          </Column>

          <Column field="nbStudents" header="Nombre d'élèves" :sortable="true" headerStyle="width:20%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title"></span>
              {{slotProps.data.nbStudents}}
            </template>
          </Column>

          <Column field="accepted" header="Validation" :sortable="true" style="text-align: right">
            <template #body="slotProps">
              <template  v-if="slotProps.data.accepted === 'waiting' || slotProps.data.accepted === 'pending'">
                <Button icon="pi pi-check" class="button-custom button-valid state-badge status-valid mr-2" @click="openReservationConfirm(slotProps.data, 'valid')" />
                <Button icon ="pi pi-envelope"  class="button-custom button-info state-badge  mr-2" @click="openSendMail(slotProps.data)" />
              <Button icon="pi pi-trash" class="button-custom button-reject state-badge status-rejected" @click="openReservationConfirm(slotProps.data, 'reject')" />
              </template>
              <span v-if="slotProps.data.accepted == 'valid'" class="state-badge status-valid">Validé <i class="pi pi-check ml-2" style="color: green"/></span>
              <span v-if="slotProps.data.accepted == 'reject'" class="state-badge status-rejected">Refusé <i class="pi pi-trash ml-2" style="color:indianred"/></span>

            </template>
          </Column>
          <Column field="spectacleReservationId" header="Actions" style="text-align: right">
            <template #body="slotProps">
            <Button icon="pi pi-save" class="button-custom button-valid" @click="downloadBill(slotProps.data.spectacleReservationId)" />
            </template>
          </Column>

        </DataTable>
      </div>
    </div>
  </div>

  <Dialog v-model:visible="confirmValidation" :style="{width: '450px'}" header="Confirmer" :modal="true">
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
      <span v-if="spectacle">Voulez-vous vraiment {{tempReservationStateTrad}} la reservation de l'école <b>{{reservation.name}}</b> ?</span>
    </div>
    <br>
    <div class="col-12">
      <label class="mb-1">Commentaire (optionnel) :</label>
    <Textarea v-model="this.reservation.comment" :autoResize="true" class="col-12 mt-1"></Textarea>
    </div>


    <div class="col-12" v-if="tempReservationStateTrad === 'valider'">
        <ak-input-number v-model="this.reservation.freeGuide" label="Nombres d'accompagnateurs gratuits :"/>
      <Slider v-model="this.reservation.freeGuide" :min="2" max="15" class="col-12 mt-1"/>
    </div>

    <div class="p-dialog-footer">
      <Button label="Non" icon="pi pi-times" class="p-button-text" @click="closeReservationConfirm"/>
      <Button label="Oui" icon="pi pi-check" class="p-button-text" @click="updateReservation" />
    </div>
  </Dialog>

  <Dialog v-model:visible="sendMailPopup" :style="{width: '450px'}" header="Prendre contact ... " :modal="true">

    <div class="col-12">
      <label class="mb-1">Message à envoyer :</label>
      <Textarea v-model="this.reservation.comment" :autoResize="true" class="col-12 mt-1"></Textarea>
    </div>

    <div class="p-dialog-footer">
      <Button label="Envoyer" icon="pi pi-check" class="p-button-text" @click="updateReservation" />
    </div>
  </Dialog>

</template>

<script>
import SpectacleService from "@/service/SpectacleService";
import SpectacleReservationService from "@/service/SpectacleReservationService";
import AkInputNumber from "@/components/input/AkInputNumber";

export default {
  components: {AkInputNumber},
  data() {
    return {
      value:null,
      spectacle: {},
      reservations: [],
      loadingReservations: false,
      confirmValidation: false,
      reservation: {},
      tempReservationState: null,
      sendMailPopup : false,
    }
  },

  spectacleService: null,
  spectacleReservationService: null,
  created() {
    this.spectacleService = new SpectacleService();
    this.spectacleReservationService = new SpectacleReservationService();
  },

  methods: {
    openReservationConfirm(reservation, state){
      this.reservation = reservation;
      this.tempReservationState = state;
      this.confirmValidation = true;
    },

    openSendMail(reservation){
      this.reservation = reservation;
      this.sendMailPopup = true;
      this.tempReservationState = "waiting";


    },

    closeReservationConfirm(){
      this.reservation = undefined;
      this.tempReservationState = undefined;
      this.confirmValidation = false;
    },
    updateReservation(){
      this.reservation.accepted = this.tempReservationState;
      this.spectacleReservationService.update(this.reservation).then(
          this.$toast.add({severity:'success', summary: 'Envoyé', detail: "L'email a bien été envoyé", life: 3000})).catch(() => {
        this.$toast.add({severity:'error', summary: 'Erreur', detail: "Une erreur est survenue", life: 3000});
      });
      this.confirmValidation = false;
      this.sendMailPopup = false;

    },
    downloadBill(spectacleReservationId){
      this.spectacleReservationService.getBill(spectacleReservationId);
    }
  },

  computed: {
    tempReservationStateTrad() {
      if(this.tempReservationState == 'valid'){
        return 'valider';
      }
      else return 'refuser';
    }
  },

  mounted() {
    this.loadingReservations = true;
    this.spectacleService.spectacle(this.$route.params.spectacleId).then(data => {
      this.spectacle = data;
    });
    let filters = {
      spectacleId: this.$route.params.spectacleId
    }
    this.spectacleReservationService.spectacleReservations(filters).then(data =>{
      this.reservations = data;
      this.loadingReservations = false;
    })

  },

}
</script>

<style scoped>

</style>